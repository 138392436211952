<template>
  <section class="SkilTest">
    <iq-card
      class="
        iq-card
        headerCardTitle
        p-3
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <div class="title">
        <i class="ri-arrow-left-s-line mr-3" @click="$router.back(-1)"></i>
        Skills certification examination
      </div>
      <div class="timer">
        <span>{{
          hour
            ? hourString + ":" + minuteString + ":" + secondString
            : minuteString + ":" + secondString
        }}</span>
      </div>
    </iq-card>
    <!-- 考题 -->
    <iq-card class="iq-card cardContent">
      <div>
        <ul class="p-0">
          <li v-for="(v, i) in text.topic" :key="i" class="mb-4">
            <div class="mb-2 title">{{ i + 1 }}. {{ v.title }}</div>
            <ul class="pl-2 d-flex justify-content-between flex-wrap options">
              <li v-for="(item, index) in v.option" :key="index">
                <div class="form-check">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="radio"
                      v-model="answerList[i]"
                      :value="index"
                    />
                    {{ item }}
                  </label>
                </div>
              </li>
            </ul>
          </li>
          <button
            class="mt-5 btn btn-primary"
            @click="courseCertificateTopicSubmit"
          >
            Submit
          </button>
        </ul>
      </div>
    </iq-card>
  </section>
</template>
<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      select: "",
      hour: "",
      minute: "",
      second: "",
      promiseTimer: "",
      text: [],
      result: {},
      answerList: [],
      timestart: "",
    };
  },
  mounted() {
    this.courseCertificateTopic();
  },
  computed: {
    hourString() {
      return this.formatNum(this.hour);
    },
    minuteString() {
      return this.formatNum(this.minute);
    },
    secondString() {
      return this.formatNum(this.second);
    },
  },
  methods: {
    // 获取试卷信息
    courseCertificateTopic() {
      this.timestart = Date.parse(new Date());
      this.$http
        .courseCertificateTopic({
          certificate_id: this.$route.query.id, //证书id
        })
        .then((res) => {
          if (res.status == 200) {
            this.text = res.data;
            let time = Number(res.data.time);
            let list = res.data.topic;
            if (list) {
              list.forEach(() => this.answerList.push(false));
            }
            if (time > 0) {
              this.hour = Math.floor(time / 60);
              this.minute = time % 60;
              this.second = 0;
              // console.log(this.hour, this.minute, this.second);
              this.countDowm();
            }
          }
        });
    },
    // 提交试卷
    async courseCertificateTopicSubmit() {
      let answer = {};
      let list = this.text.topic;
      if (list) {
        list.forEach((v, i) => {
          let item = this.answerList[i];
          item >= 0 ? (answer[v.id] = item) : (answer[v.id] = " ");
        });
        // console.log(answer);
      }
      let examin = {};
      examin = { ...answer };
      let res = await this.$http.courseCertificateTopicSubmit({
        certificate_id: this.$route.query.id,
        exam: examin,
      });
      if (res.status == 200) {
        // console.log(res.data);
        let score = res.data.total_points;
        let price = res.data.blockchain_certificate_price;
        let pass = res.data.pass;
        this.$router.push(
          `/TestResult?score=${score}&price=${price}&id=${this.$route.query.id}&pass=${pass}`
        );
        clearInterval(this.promiseTimer);
      }
    },
    // 倒计时
    countDowm() {
      clearInterval(this.promiseTimer);
      this.promiseTimer = setInterval(() => {
        if (this.hour == 0 && this.minute == 0 && this.second == 0) {
          clearInterval(this.promiseTimer);
          this.courseCertificateTopicSubmit();
        } else if (this.hour === 0) {
          if (this.minute !== 0 && this.second === 0) {
            this.second = 59;
            this.minute -= 1;
          } else if (this.minute === 0 && this.second === 0) {
            this.second = 0;
            // this.$emit("countDowmEnd", true);
            clearInterval(this.promiseTimer);
          } else {
            this.second -= 1;
          }
        } else {
          if (this.minute !== 0 && this.second === 0) {
            this.second = 59;
            this.minute -= 1;
          } else if (this.minute === 0 && this.second === 0) {
            this.hour -= 1;
            this.minute = 59;
            this.second = 59;
          } else {
            this.second -= 1;
          }
        }
      }, 1000);
    },
    formatNum(num) {
      return num < 10 ? "0" + num : "" + num;
    },
  },
};
</script>
<style src='' lang='scss' scoped>
.container {
  width: 960px;
  .SkilTest {
    .headerCardTitle {
      min-height: 65px;
      .title {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }
      .timer {
        color: #ef5959;
      }
    }
    .cardContent {
      padding: 29px 56px 22px 41px;
      .title {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 24px;
      }
      .options {
        li {
          min-width: 50%;
        }
      }
    }
  }
}
</style>
